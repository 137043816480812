<template>
  <div id="index">
    <iframe v-if="buildingData.background_vr" :src="buildingData.background_vr" frameborder="0" width="100%"
      height='100%' class="iframe"></iframe>
    <div class="head-full">
      <div class="head" @click='handleGoLogin'><img src="../assets/image/head_cion.png" alt="" v-if="!isLogin"><img
          :src="headImg" alt="" v-if="isLogin"></div>
      <div class="full" @click="handleFull"><img src="../assets/image/full_icon.png" alt=""></div>
      <div class='music' v-if="isMusic"><img src="../assets/image/music_icon.png" alt=""
          :class="[playAudio?'play-music':'']" @click="handleMusic"></div>
      <div class="music" @click="handleJump" v-if="isWeb"><img src="../assets/image/web_icon.png" alt=""></div>
    </div>
    <div class="info">
      <div class="title">{{buildingData.building_name}}</div>
      <div class="tags">
        <div class="tag" v-for="item in tags" @click="handleJumpTag(item)">
          <div class="tag-name">{{item.name}}</div>
          <img :src="item.img" alt="" class="tag-img">
        </div>
      </div>
      <!-- <div class="text" v-if="building_id != 115">{{buildingData.company_name}}</div> -->
    </div>
    <!-- <div class="info-img" v-if="building_id == 115">
      <img class="text" :src="buildingData.company_name" />
    </div> -->
    <footer>
      <router-link
        :to="{path:item.jump_url,query:{panorama_id:item.panorama_id,channel_id:item.channel_id,id:buildingData.id}}"
        tag="li" v-for="(item, index) in btnData" class="router-li" v-if="item.phoneStatus" :key="index">
        <img class="icon-home" :src="item.label_image">
        <div class="text-content">
          <p class="text-cn">{{item.label_name}}</p>
          <p class="text-en">{{item.small_label_name}}</p>
        </div>
      </router-link>
    </footer>
    <el-dialog title="提示" :visible.sync="outVisible" width="30%" :before-close="handleClose">
      <span>确定退出？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outVisible = false">取 消</el-button>
        <el-button type="primary" @click="goOut">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <img src="../assets/image/cat.gif" alt="" class="cat"> -->
  </div>
</template>

<script>
  const music = new Audio()
  export default {
    name: 'index',
    data() {
      return {
        btnData: [],
        buildingData: {},
        flag: 0,
        panorama_id: "",
        channel_id: "",
        building_id: "",
        user_id: "",
        headImg: '',
        isLogin: false,
        adminLogin: false,
        outVisible: false,
        playAudio: false,
        isMusic: '',
        isWeb: "",
        tags: []
      }
    },
    methods: {
      getData() {
        this.panorama_id = this.getQueryString('panorama_id')
        // this.channel_id = this.getQueryString('channel_id')
        this.user_id = this.getQueryString('user_id');
        this.building_id = this.getQueryString('building_id')
        this.$axios({
          method: "POST",
          url: "api/v5/panorama/index",
          data: {
            building_id: this.building_id,
            user_id: this.user_id,
            panorama_id: this.panorama_id
          },
          headers: {
            "panorama-api-token": sessionStorage.getItem('token')
          }
        }).then(res => {
          this.buildingData = res.data.data;
          this.btnData = res.data.data.button;
          this.adminLogin = res.data.data.adminLogin
          this.isWeb = res.data.data.official_url
          if (res.data.data.music) {
            this.isMusic = JSON.parse(res.data.data.music).senceSettings[0].path
            music.loop = true;
            music.src = this.QINIU_CDN_HOST + JSON.parse(res.data.data.music).senceSettings[0].path;
          }
          this.tags = JSON.parse(res.data.data.index_labels)
          // localStorage.setItem("bgType", res.data.data.background_type);
          localStorage.setItem("bgVr", res.data.data.background_vr);
          localStorage.setItem("bgImg", res.data.data.background_image);
          sessionStorage.setItem("title", res.data.data.building_name);
          sessionStorage.setItem("panoramaId", res.data.data.id);
          sessionStorage.setItem("buildingId", this.getQueryString('building_id'));
          document.title = res.data.data.building_name;
          if (sessionStorage.getItem('token')) {
            this.isLogin = true
            console.log(this.adminLogin)
            if (this.adminLogin) {
              this.headImg = require('@/assets/image/index_in.png')
            } else {
              this.headImg = require('@/assets/image/index_out.png')
            }
          } else {
            this.isLogin = false
          }
        })
      },
      handleGoLogin() {
        if (sessionStorage.getItem('token') && this.adminLogin) {
          this.$router.push({
            path: "/manage-index"
          })
        } else if (!sessionStorage.getItem('token')) {
          this.$router.push({
            path: "/login",
            query: {
              resource: 'index'
            }
          })
        } else if (sessionStorage.getItem('token') && !this.adminLogin) {
          this.outVisible = true
        }
      },
      goOut() {
        this.$axios({
          method: 'post',
          url: 'api/v5/panorama/loginOut',
          headers: {
            // "panorama-api-token": '8bd7bc3d18c055c3eefbc311631b705e'
            "panorama-api-token": sessionStorage.getItem('token')
          }
        }).then(res => {
          if (res.status == '200') {
            sessionStorage.removeItem('token')
            this.outVisible = false
            this.isLogin = false
          }
        })
      },
      handleTag(i) {
        console.log(i)
        // debugger;
        window.open(i.url, "_self")
      },
      handleJumpTag(i){
        this.debounce(this.handleTag(i),1000)
      },
      debounce(fn, delay) {
        let timer = null //借助闭包
        return function () {
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(fn, delay) // 简化写法
        }
      },
      handleMusic() {
        let that = this;
        if (that.playAudio) {
          // 关闭
          if (that.isMusic) {
            music.pause();
          }
        } else {
          // 播放
          if (that.isMusic) {
            music.play();
          }
        }
        that.playAudio = !that.playAudio;
      },
      handleClickRouter() {
        music.pause()
      },
      handleClose() {
        this.outVisible = false
      },
      handleFull() {
        // var element = document.documentElement;
        // if (element.requestFullscreen) {
        //   element.requestFullscreen();
        // } else if (element.msRequestFullscreen) {
        //   element.msRequestFullscreen();
        // } else if (element.mozRequestFullScreen) {
        //   element.mozRequestFullScreen();
        // } else if (element.webkitRequestFullscreen) {
        //   element.webkitRequestFullscreen();
        // }
        this.flag++;
        this.flag % 2 == 0
          ? this.outFullCreeen(document)
          : this.FullCreeen(document.documentElement);
      },
      handleJump() {
        // self.location.href="http://www.luenmei.com"
        self.location.href = this.isWeb
      },
      // 进入全屏的方法
      FullCreeen(element) {
        let el = element;
        let rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen;
        // Element.requestFullscreen() 此方法用于发出异步请求使元素进入全屏模式。
        // 调用此API并不能保证元素一定能够进入全屏模式。如果元素被允许进入全屏幕模式，
        // document对象会收到一个fullscreenchange事件，通知调用者当前元素已经进入全屏模式。如果全屏请求不被许可，则会收到一个fullscreenerror事件。
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript != null) {
            wscript.SendKeys("{F11}");
          }
        }
      },
      //退出全屏的方法
      outFullCreeen(element) {
        let el = element;
        let cfs =
          el.cancelFullScreen ||
          el.webkitCancelFullScreen ||
          el.mozCancelFullScreen ||
          el.exitFullScreen;
        if (typeof cfs != "undefined" && cfs) {
          cfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript != null) {
            wscript.SendKeys("{F11}");
          }
        }
      },
      getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
      }
    },
    beforeDestroy() {
      music.pause()
    },
    created() {
      sessionStorage.setItem('building_id', this.getQueryString('building_id'))
      sessionStorage.setItem('user_id', this.getQueryString('user_id'))
      sessionStorage.setItem('panorama_id', this.getQueryString('panorama_id'))
      this.getData()
      var is_mobi = navigator.userAgent.toLowerCase().match(/(iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
      //   if (window.location.host == "panorama.comegogogo.com" || window.location.host == "panorama.mobile.comegogogo.com") {
      //     apiUrl = "https://www.comegogogo.com/";
      // }else {
      //     apiUrl = 'https://test.comegogogo.com/';
      // }
      if (is_mobi) {
        window.location.href = "http://panorama.mobile.comegogogo.com/?building_id=" + this.getQueryString('building_id') + "&user_id=" + this.getQueryString('user_id') + "&panorama_id=" + this.getQueryString('panorama_id');
      }
    }
  }
</script>

<style lang="scss" scoped>
  #index {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* background: url("../assets/image/bg.png") no-repeat; */
    background-size: 100% 100%;
  }

  .head-full {
    position: absolute;
    display: flex;
    top: 73px;
    right: 148px;

    .head,
    .full,
    .music {
      width: 36px;
      height: 36px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 18px;
      }
    }

    .head {
      margin-right: 42px;
    }

    .music {
      margin-left: 42px;
    }
  }

  .info-img {
    position: absolute;
    top: 0px;
    left: 192px;
  }

  .info {
    position: absolute;
    top: 206px;
    left: 192px;

    .title {
      font-size: 72px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }

    .text {
      margin-top: 26px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  footer {
    position: absolute;
    display: flex !important;
    padding: 0 0 0 192px;
    /* justify-content: space-between; */
    align-items: center;
    bottom: 100px;
    left: 0;
    width: 100%;
    height: 160px;
    box-sizing: border-box;
  }

  footer li {
    list-style: none;
  }

  .router-li {
    margin-right: 120px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-home {
      width: 60px;
      height: 60px;
    }

    .text-content {
      margin-left: 19px;

      .text-cn {
        margin: 0;
        font-size: 30px;
        color: #fff;
        line-height: 1;
      }

      .text-en {
        margin: 10px 0 0 0;
        font-size: 20px;
        color: #fff;
        line-height: 1;
      }
    }
  }

  @keyframes turn {
    0% {
      -webkit-transform: rotate(0deg);
      /* transform-origin:20% center; */
    }

    100% {
      -webkit-transform: rotate(360deg);
      /* -webkit-transform-origin:center bottom 0; */
    }
  }

  .play-music {
    /* -webkit-transform-style: preserve-3d; */
    animation: turn 3s linear infinite;
  }

  /* .cat{
    position: absolute;
    bottom:40px;
    right:60px;
    width:150px;
    height:auto;
  } */
  .tags {
    margin-top: 30px;
    display: flex;

    .tag {
      display: flex;
      margin-right: 37px;
      padding: 0 24px;
      align-items: center;
      height: 40px;
      text-align: center;
      background: rgba(0, 0, 0, .25);
      border-radius: 20px;
      /* opacity: 0.25; */
      cursor: pointer;

      .tag-name {
        margin-right: 30px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .tag-img {
        /* display: inline-block; */
        width: 21px;
        height: 21px
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>